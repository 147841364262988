import React from "react"
//import { gsap, Quad } from "gsap/all"
import Tree from "./tree"



const NUM_TREE_TYPES = 7;


class Trees extends React.Component {
  
  /*constructor(props) {
    super(props);
  }*/

  updateState = (_isRendered) => {
    this.setState({isRendered: _isRendered});
  }

  componentDidMount = () => {
    //if(this.props.treeArea === "title"){
      //
    //}
  }

  
  
  updateTrees = (properties) => {

    let count = Math.floor(properties.count);
    let treeArea = properties.treeArea;
    
    //console.log("updateTrees: " + treeArea + ", count: " + count + ", numTreesRemoved: " + properties.numTreesRemoved);

    if(count > properties.numTreesRemoved){
      //numTreesRemoved++;
      let diff = count - properties.numTreesRemoved;
      let treeNum;
      //console.log("diff: " + diff);

      if(diff > 1){
        //remove more than 1 tree to catch up

        for (var i = 0; i < diff; i++) {
          //console.log("numTreesRemoved: " + numTreesRemoved);
          properties.numTreesRemoved++;
          treeNum = properties.trees[properties.numTreesRemoved-1];
          //console.log("treeNum: " + treeNum + ", treeArea: " + treeArea);
          try{
            this.refs[`${treeArea}-${treeNum}`].hideTree();
          } catch (e){
            //console.log(e);
            console.log("error removing: " + properties.numTreesRemoved + ", treeArea: " + treeArea);
          }
        }

      } else {
        properties.numTreesRemoved++
        treeNum = properties.trees[properties.numTreesRemoved-1];
        //console.log("treeNum: " + treeNum + ", treeArea: " + treeArea);
        try{
          this.refs[`${treeArea}-${treeNum}`].hideTree();
        } catch (e){
          //console.log(e);
          console.log("error removing: " + properties.numTreesRemoved + ", treeArea: " + treeArea);
        }
        //console.log(treeArea + " totalTrees: " + totalTrees + " numTreesRemoved: " + numTreesRemoved + ", treeNum: " + treeNum);
      }
      
    }
  }
  
  render() {
    
    if(this.props.trees.trees.length === 0){
      
      this.props.trees.trees = this.props.treePositions.map((tree, i) => {
        let treeType = Math.ceil(Math.random() * Math.ceil(NUM_TREE_TYPES));
        //console.log("ref: " + (this.props.treeArea+"-"+(i+1)));
        return <Tree key={i} num={i} type={treeType} style={{left: tree.x, top: tree.y, position: 'absolute'}} ref={this.props.treeArea+"-"+(i+1)} file={this.props.globalState.cdnUrl + this.props.data["tree"+treeType]}  />
          
      });
    }

    return (
      <div ref="treesRef" >
        {this.props.trees.trees}
      </div>
    )
  }
}

export default Trees
