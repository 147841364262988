import React from "react"
import { gsap, Quad } from "gsap"


//const Tree = ({ num, file }) => {
class Tree extends React.Component {

    constructor(props) {
        super(props);
        //this.treeRef = null;
        this.state = { grow: '' }
        
    }
    
    showTree = () => {
        gsap.to(this.refs.treeRef, 0.3, {scale:1, opacity: 1, ease: Quad.easeOut})
    }

    hideTree = () => {
        //console.log("hiding tree: " + this.props.num);
        //gsap.to(this.refs.treeRef, 0, { autoAlpha: 0});
        this.refs.treeRef.style.display = "none";
    }

    render(){
        return (
            <div className={"tree tree--" + this.props.num + " type-" + this.props.type}>
                <img ref="treeRef" src={ this.props.file } alt="tree" style={ this.props.style} />
            </div>
        );
    }
}

export default Tree
