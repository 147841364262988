/** Town 1 **/

import React from "react";
import { preloadImages } from "../../helpers"
import PrevNext from "../../components/navigation/prevnext"
import SEO from "../../components/seo"
import AssetBomber from "../../components/town/assetbomber";
import Trees from "../../components/town/trees"
import { gsap, Quad } from "gsap/all"
import { shuffle } from "../../helpers"

let globalState, data, preloadData;
let aniTitleProps, aniTextProps, aniMapProps;
let titleTrees, textTrees, mapTrees, staticTrees, staticBottomTrees;

const treeSpeed = 3;



let staticTreePositions = [
    {x: 72, y: 4},
    {x: 104, y: -3},
    {x: 141, y: -1},
    {x: 384, y: 0},
    {x: 423, y: 3},
    {x: 910, y: -1},
    {x: 33, y: 53},
    {x: 206, y: 53},
    {x: 315, y: 47},
    {x: 942, y: 53},
    {x: 1328, y: 51},
    {x: 384, y: 103},
    {x: 1330, y: 96},
    {x: 1363, y: 104},
    {x: 1543, y: 104},
    {x: 104, y: 148},
    {x: 175, y: 152},
    {x: 242, y: 150},
    {x: 316, y: 153},
    {x: 353, y: 153},
    {x: 1540, y: 154},
    {x: 281, y: 200},
    {x: 319, y: 197},
    {x: 1328, y: 199},
    {x: 1362, y: 203},
    {x: 70, y: 252},
    {x: 104, y: 252},
    {x: 245, y: 250},
    {x: 1329, y: 247},
    {x: 1367, y: 246},
    {x: 1404, y: 249},
    {x: 1432, y: 250},
    {x: 1471, y: 249},
    {x: 37, y: 297},
    {x: 71, y: 297},
    {x: 101, y: 296},
    {x: 175, y: 300},
    {x: 1363, y: 304},
    {x: 74, y: 349},
    {x: 141, y: 350},
    {x: 172, y: 349},
    {x: 1291, y: 349},
    {x: 39, y: 400},
    {x: 1296, y: 396},
    {x: 1328, y: 403},
    {x: 1543, y: 398},
    {x: 38, y: 446},
    {x: 69, y: 453},
    {x: 1400, y: 447},
    {x: 1397, y: 503},
    {x: 1433, y: 498},
    {x: 1470, y: 497},
    {x: 73, y: 554},
    {x: 1400, y: 550},
    {x: 1436, y: 552},
    {x: 1467, y: 551},
    {x: 37, y: 653},
    {x: 1330, y: 650},
    {x: 1397, y: 700},
    {x: 1294, y: 800},
    {x: 1328, y: 800},
    {x: 1541, y: 801},
    {x: 1362, y: 846},
    {x: 1432, y: 853}
  ];

let titleTreePositions = [
    {x: 966, y: 4},  
    {x: 935, y: 49},
    {x: 967, y: 52},   
    {x: 1003, y: 52},    
    {x: 1042, y: 46},
    {x: 1072, y: 52},
    {x: 1114, y: 49},
    {x: 1148, y: 53},
    {x: 1184, y: 46},
    {x: 1212, y: 46},
    {x: 1254, y: 48},    
    {x: 1281, y: 50},    
    {x: 1320, y: 49},    
    {x: 1357, y: 51},    
    {x: 936, y: 96},   
    {x: 973, y: 97}, 
    {x: 1005, y: 99},
    {x: 1041, y: 100},
    {x: 1073, y: 99},   
    {x: 1106, y: 101},
    {x: 1149, y: 99},
    {x: 1184, y: 102},
    {x: 1217, y: 100},
    {x: 1246, y: 99},
    {x: 1288, y: 96},
    {x: 1322, y: 100},
    {x: 1387, y: 102},
    {x: 934, y: 151},
    {x: 971, y: 149},
    {x: 1001, y: 152},
    {x: 1040, y: 150},
    {x: 1076, y: 152},
    {x: 1114, y: 149},
    {x: 1144, y: 146},
    {x: 1176, y: 152},
    {x: 1214, y: 153},
    {x: 1253, y: 148},
    {x: 1287, y: 150},
    {x: 1320, y: 150},
    {x: 1353, y: 147},
    {x: 932, y: 196},
    {x: 971, y: 200},
    {x: 1001, y: 200},
    {x: 1038, y: 197},
    {x: 1079, y: 197},
    {x: 1112, y: 200},
    {x: 1146, y: 202},
    {x: 1178, y: 198},
    {x: 1215, y: 201},
    {x: 1253, y: 196},
    {x: 1284, y: 201},
    {x: 1320, y: 198},
    {x: 934, y: 246},
    {x: 967, y: 249},
    {x: 1002, y: 250},
    {x: 1038, y: 251},
    {x: 1075, y: 253},
    {x: 1111, y: 252},
    {x: 1147, y: 254},
    {x: 1177, y: 251},
    {x: 1214, y: 246},
    {x: 1249, y: 249},
    {x: 1288, y: 253},
    {x: 1320, y: 253},
    {x: 1358, y: 253}, 
]; 

let textTreePositions = [
    {x: 938, y: 310},
    {x: 969, y: 312},
    {x: 1006, y: 310},
    {x: 1040, y: 307},
    {x: 1072, y: 308},
    {x: 1114, y: 312},
    {x: 1142, y: 311},
    {x: 1184, y: 307},
    {x: 1213, y: 310},
    {x: 1246, y: 313},
    {x: 1285, y: 309},
    {x: 1317, y: 310},
    {x: 1355, y: 311},
    {x: 1386, y: 307},
    {x: 935, y: 360},
    {x: 968, y: 356},
    {x: 1006, y: 358},
    {x: 1038, y: 360},
    {x: 1073, y: 362},
    {x: 1114, y: 360},
    {x: 1144, y: 360},
    {x: 1177, y: 356},
    {x: 1215, y: 364},
    {x: 1253, y: 360},
    {x: 1282, y: 360},
    {x: 1317, y: 361},
    {x: 1358, y: 358},
    {x: 1387, y: 360},
    {x: 1499, y: 361},
    {x: 935, y: 413},
    {x: 966, y: 406},
    {x: 1003, y: 406},
    {x: 1037, y: 412},
    {x: 1071, y: 406},
    {x: 1112, y: 407},
    {x: 1145, y: 410},
    {x: 1179, y: 413},
    {x: 1214, y: 410},
    {x: 1247, y: 411},
    {x: 1285, y: 410},
    {x: 1322, y: 414},
    {x: 1356, y: 410},
    {x: 1390, y: 408},
    {x: 1428, y: 411},
    {x: 902, y: 457},
    {x: 939, y: 458},
    {x: 974, y: 456},
    {x: 1008, y: 460},
    {x: 1040, y: 462},
    {x: 1075, y: 462},
    {x: 1114, y: 460},
    {x: 1145, y: 461},
    {x: 1176, y: 462},
    {x: 1214, y: 461},
    {x: 1251, y: 458},
    {x: 1281, y: 460},
    {x: 1318, y: 460},
    {x: 1355, y: 460},
    {x: 1390, y: 459},
    {x: 936, y: 509},
    {x: 972, y: 506},
    {x: 1005, y: 513},
    {x: 1042, y: 508},
    {x: 1074, y: 512},
    {x: 1109, y: 514},
    {x: 1145, y: 510},
    {x: 1179, y: 506},
    {x: 1214, y: 506},
    {x: 1254, y: 508},
    {x: 1285, y: 512},
    {x: 1320, y: 510},
    {x: 1355, y: 507},
    {x: 934, y: 561},
    {x: 968, y: 560},
    {x: 1006, y: 563},
    {x: 1038, y: 558},
    {x: 1077, y: 563},
    {x: 1113, y: 561},
    {x: 1146, y: 557},
    {x: 1183, y: 557},
    {x: 1212, y: 557},
    {x: 1254, y: 560},
    {x: 1288, y: 559},
    {x: 1316, y: 557},
    {x: 1353, y: 561},
    {x: 1394, y: 562},
    {x: 1428, y: 560},
    {x: 934, y: 611},
    {x: 972, y: 611},
    {x: 1002, y: 612},
    {x: 1043, y: 612},
    {x: 1075, y: 610},
    {x: 1114, y: 608},
    {x: 1143, y: 613},
    {x: 1184, y: 609},
    {x: 1215, y: 613},
    {x: 1248, y: 608},
    {x: 1281, y: 610},
    {x: 1319, y: 611},
    {x: 1355, y: 612},
    {x: 1393, y: 607},
    {x: 901, y: 662},
    {x: 934, y: 656},
    {x: 967, y: 661},
    {x: 1002, y: 658},
    {x: 1041, y: 662},
    {x: 1073, y: 657},
    {x: 1113, y: 660},
    {x: 1143, y: 664},
    {x: 1178, y: 659},
    {x: 1212, y: 657},
    {x: 1252, y: 661},
    {x: 1288, y: 663},
    {x: 1318, y: 660},
    {x: 1354, y: 663},
    {x: 1390, y: 660},
    {x: 1424, y: 664},
    {x: 903, y: 709},
    {x: 932, y: 708},
    {x: 967, y: 708},
    {x: 1005, y: 711},
    {x: 1038, y: 710},
    {x: 1077, y: 707},
    {x: 1110, y: 714},
    {x: 1144, y: 710},
    {x: 1176, y: 706},
    {x: 1216, y: 710},
    {x: 1254, y: 711},
    {x: 1288, y: 709},
    {x: 1322, y: 714},
    {x: 1358, y: 706},
    {x: 1387, y: 712},
    {x: 1425, y: 709},
    {x: 1493, y: 707},
    {x: 903, y: 760},
    {x: 936, y: 762},
    {x: 968, y: 760},
    {x: 1002, y: 763},
    {x: 1036, y: 760},
    {x: 1077, y: 763},
    {x: 1110, y: 761},
    {x: 1142, y: 758},
    {x: 1180, y: 756},
    {x: 1216, y: 761},
    {x: 1249, y: 757},
    {x: 1289, y: 761},
    {x: 1324, y: 762},
    {x: 1355, y: 760},
    {x: 1495, y: 761},
    {x: 1042, y: 807},
    {x: 1107, y: 814},
    {x: 1145, y: 812},
    {x: 1180, y: 814},
    {x: 1248, y: 810},
    {x: 1320, y: 806},
    {x: 933, y: 856},
    {x: 1181, y: 862},
    {x: 1461, y: 860},
    {x: 1007, y: 906},
    {x: 1112, y: 911},
    {x: 1284, y: 907},
    {x: 1393, y: 910},
    {x: 1182, y: 962},
];

let mapTreePositions = [
    {x: 96, y: 4},
    {x: 165, y: 2},
    {x: 202, y: 2},
    {x: 273, y: 4},
    {x: 301, y: 0},
    {x: 409, y: 4},
    {x: 445, y: 0},
    {x: 479, y: 2},
    {x: 581, y: 0},
    {x: 95, y: 46},
    {x: 129, y: 52},
    {x: 163, y: 52},
    {x: 201, y: 54},
    {x: 235, y: 50},
    {x: 271, y: 54},
    {x: 303, y: 51},
    {x: 337, y: 50},
    {x: 371, y: 46},
    {x: 413, y: 50},
    {x: 442, y: 50},
    {x: 478, y: 48},
    {x: 514, y: 46},
    {x: 546, y: 47},
    {x: 584, y: 51},
    {x: 621, y: 51},
    {x: 658, y: 52},
    {x: 688, y: 52},
    {x: 727, y: 50},
    {x: 758, y: 46},
    {x: 799, y: 46},
    {x: 830, y: 49},
    {x: 865, y: 50},
    {x: 901, y: 46},
    {x: 96, y: 99},
    {x: 133, y: 101},
    {x: 161, y: 96},
    {x: 201, y: 98},
    {x: 234, y: 97},
    {x: 266, y: 103},
    {x: 308, y: 96},
    {x: 340, y: 97},
    {x: 375, y: 101},
    {x: 408, y: 101},
    {x: 443, y: 99},
    {x: 481, y: 99},
    {x: 518, y: 103},
    {x: 549, y: 102},
    {x: 584, y: 102},
    {x: 622, y: 104},
    {x: 652, y: 102},
    {x: 693, y: 100},
    {x: 725, y: 103},
    {x: 757, y: 100},
    {x: 791, y: 101},
    {x: 832, y: 103},
    {x: 864, y: 103},
    {x: 900, y: 98},
    {x: 95, y: 154},
    {x: 126, y: 147},
    {x: 165, y: 146},
    {x: 204, y: 153},
    {x: 238, y: 154},
    {x: 274, y: 147},
    {x: 306, y: 151},
    {x: 343, y: 148},
    {x: 374, y: 149},
    {x: 408, y: 147},
    {x: 445, y: 147},
    {x: 476, y: 154},
    {x: 515, y: 150},
    {x: 554, y: 150},
    {x: 587, y: 152},
    {x: 622, y: 150},
    {x: 655, y: 149},
    {x: 690, y: 153},
    {x: 724, y: 150},
    {x: 764, y: 149},
    {x: 797, y: 147},
    {x: 830, y: 149},
    {x: 863, y: 150},
    {x: 898, y: 149},
    {x: 97, y: 196},
    {x: 127, y: 203},
    {x: 165, y: 203},
    {x: 197, y: 199},
    {x: 235, y: 198},
    {x: 271, y: 203},
    {x: 305, y: 196},
    {x: 336, y: 202},
    {x: 372, y: 199},
    {x: 411, y: 198},
    {x: 449, y: 196},
    {x: 477, y: 197},
    {x: 516, y: 199},
    {x: 550, y: 200},
    {x: 583, y: 196},
    {x: 621, y: 201},
    {x: 658, y: 198},
    {x: 693, y: 196},
    {x: 723, y: 196},
    {x: 759, y: 196},
    {x: 797, y: 198},
    {x: 826, y: 198},
    {x: 868, y: 198},
    {x: 902, y: 201},
    {x: 96, y: 253},
    {x: 130, y: 246},
    {x: 163, y: 253},
    {x: 200, y: 249},
    {x: 233, y: 249},
    {x: 273, y: 248},
    {x: 303, y: 247},
    {x: 340, y: 250},
    {x: 378, y: 252},
    {x: 409, y: 246},
    {x: 448, y: 247},
    {x: 480, y: 246},
    {x: 515, y: 250},
    {x: 550, y: 254},
    {x: 586, y: 250},
    {x: 622, y: 252},
    {x: 654, y: 248},
    {x: 692, y: 248},
    {x: 729, y: 251},
    {x: 763, y: 246},
    {x: 798, y: 247},
    {x: 833, y: 252},
    {x: 865, y: 250},
    {x: 902, y: 248},
    {x: 93, y: 298},
    {x: 130, y: 298},
    {x: 169, y: 300},
    {x: 197, y: 303},
    {x: 231, y: 301},
    {x: 270, y: 298},
    {x: 302, y: 302},
    {x: 337, y: 297},
    {x: 376, y: 299},
    {x: 411, y: 299},
    {x: 445, y: 302},
    {x: 483, y: 300},
    {x: 517, y: 303},
    {x: 553, y: 300},
    {x: 584, y: 299},
    {x: 620, y: 300},
    {x: 659, y: 297},
    {x: 691, y: 299},
    {x: 721, y: 304},
    {x: 759, y: 298},
    {x: 791, y: 300},
    {x: 829, y: 302},
    {x: 868, y: 300},
    {x: 898, y: 300},
    {x: 96, y: 351},
    {x: 127, y: 350},
    {x: 162, y: 349},
    {x: 201, y: 349},
    {x: 236, y: 349},
    {x: 270, y: 349},
    {x: 304, y: 347},
    {x: 344, y: 353},
    {x: 375, y: 347},
    {x: 411, y: 346},
    {x: 446, y: 350},
    {x: 484, y: 346},
    {x: 516, y: 352},
    {x: 546, y: 350},
    {x: 589, y: 348},
    {x: 616, y: 351},
    {x: 657, y: 353},
    {x: 689, y: 351},
    {x: 726, y: 350},
    {x: 764, y: 353},
    {x: 795, y: 354},
    {x: 834, y: 350},
    {x: 862, y: 347},
    {x: 896, y: 352},
    {x: 97, y: 397},
    {x: 127, y: 402},
    {x: 162, y: 404},
    {x: 203, y: 400},
    {x: 233, y: 400},
    {x: 270, y: 402},
    {x: 307, y: 403},
    {x: 340, y: 396},
    {x: 372, y: 398},
    {x: 410, y: 402},
    {x: 442, y: 398},
    {x: 484, y: 404},
    {x: 519, y: 398},
    {x: 549, y: 400},
    {x: 582, y: 404},
    {x: 623, y: 396},
    {x: 658, y: 401},
    {x: 691, y: 396},
    {x: 723, y: 403},
    {x: 764, y: 402},
    {x: 795, y: 396},
    {x: 833, y: 400},
    {x: 861, y: 403},
    {x: 902, y: 404},
    {x: 99, y: 453},
    {x: 134, y: 453},
    {x: 162, y: 446},
    {x: 198, y: 450},
    {x: 232, y: 450},
    {x: 267, y: 452},
    {x: 301, y: 452},
    {x: 336, y: 446},
    {x: 375, y: 450},
    {x: 410, y: 454},
    {x: 441, y: 451},
    {x: 483, y: 451},
    {x: 517, y: 450},
    {x: 554, y: 447},
    {x: 584, y: 452},
    {x: 618, y: 453},
    {x: 653, y: 451},
    {x: 693, y: 450},
    {x: 724, y: 452},
    {x: 762, y: 451},
    {x: 792, y: 448},
    {x: 832, y: 449},
    {x: 865, y: 446},
    {x: 898, y: 447},
    {x: 91, y: 499},
    {x: 130, y: 504},
    {x: 163, y: 502},
    {x: 197, y: 499},
    {x: 239, y: 497},
    {x: 266, y: 497},
    {x: 306, y: 500},
    {x: 344, y: 498},
    {x: 371, y: 498},
    {x: 411, y: 496},
    {x: 448, y: 500},
    {x: 476, y: 504},
    {x: 515, y: 503},
    {x: 553, y: 498},
    {x: 585, y: 502},
    {x: 618, y: 504},
    {x: 651, y: 496},
    {x: 694, y: 496},
    {x: 725, y: 499},
    {x: 756, y: 497},
    {x: 799, y: 503},
    {x: 828, y: 502},
    {x: 865, y: 498},
    {x: 900, y: 497},
    {x: 97, y: 550},
    {x: 130, y: 552},
    {x: 163, y: 552},
    {x: 197, y: 550},
    {x: 236, y: 551},
    {x: 267, y: 552},
    {x: 301, y: 546},
    {x: 341, y: 550},
    {x: 378, y: 548},
    {x: 410, y: 550},
    {x: 446, y: 546},
    {x: 480, y: 550},
    {x: 514, y: 547},
    {x: 546, y: 554},
    {x: 585, y: 549},
    {x: 620, y: 548},
    {x: 653, y: 553},
    {x: 688, y: 550},
    {x: 727, y: 550},
    {x: 757, y: 547},
    {x: 795, y: 550},
    {x: 826, y: 552},
    {x: 868, y: 548},
    {x: 903, y: 550},
    {x: 95, y: 600},
    {x: 129, y: 597},
    {x: 169, y: 602},
    {x: 196, y: 600},
    {x: 235, y: 597},
    {x: 273, y: 599},
    {x: 305, y: 599},
    {x: 340, y: 599},
    {x: 375, y: 602},
    {x: 409, y: 596},
    {x: 448, y: 604},
    {x: 479, y: 598},
    {x: 518, y: 597},
    {x: 550, y: 597},
    {x: 583, y: 603},
    {x: 619, y: 601},
    {x: 653, y: 604},
    {x: 688, y: 597},
    {x: 724, y: 601},
    {x: 763, y: 600},
    {x: 799, y: 599},
    {x: 831, y: 601},
    {x: 862, y: 601},
    {x: 898, y: 600},
    {x: 91, y: 647},
    {x: 130, y: 650},
    {x: 169, y: 649},
    {x: 199, y: 653},
    {x: 237, y: 648},
    {x: 271, y: 650},
    {x: 309, y: 650},
    {x: 339, y: 654},
    {x: 373, y: 652},
    {x: 414, y: 653},
    {x: 443, y: 650},
    {x: 483, y: 646},
    {x: 511, y: 649},
    {x: 546, y: 650},
    {x: 586, y: 646},
    {x: 622, y: 650},
    {x: 658, y: 653},
    {x: 686, y: 649},
    {x: 728, y: 648},
    {x: 758, y: 653},
    {x: 791, y: 651},
    {x: 830, y: 648},
    {x: 867, y: 653},
    {x: 901, y: 650},
    {x: 94, y: 704},
    {x: 132, y: 701},
    {x: 168, y: 703},
    {x: 198, y: 698},
    {x: 237, y: 700},
    {x: 271, y: 697},
    {x: 305, y: 701},
    {x: 336, y: 704},
    {x: 375, y: 700},
    {x: 406, y: 701},
    {x: 449, y: 704},
    {x: 480, y: 700},
    {x: 519, y: 702},
    {x: 547, y: 702},
    {x: 581, y: 696},
    {x: 623, y: 700},
    {x: 658, y: 697},
    {x: 691, y: 700},
    {x: 729, y: 702},
    {x: 756, y: 697},
    {x: 791, y: 700},
    {x: 829, y: 700},
    {x: 865, y: 700},
    {x: 900, y: 704},
    {x: 95, y: 750},
    {x: 132, y: 749},
    {x: 163, y: 754},
    {x: 196, y: 751},
    {x: 303, y: 746},
    {x: 339, y: 750},
    {x: 377, y: 747},
    {x: 414, y: 752},
    {x: 444, y: 750},
    {x: 586, y: 746},
    {x: 898, y: 750},
    {x: 130, y: 801},
    {x: 479, y: 800},
    {x: 623, y: 804},
    {x: 657, y: 798},
    {x: 689, y: 797},
    {x: 728, y: 803},
    {x: 378, y: 852},
    {x: 517, y: 848},
    {x: 624, y: 849},
    {x: 655, y: 849},
    {x: 515, y: 899},
    {x: 727, y: 896},
    {x: 761, y: 900},
    {x: 795, y: 899},
    {x: 270, y: 950},
];

let staticBottomTreePositions = [
    {x: 763, y: 540},
    {x: 696, y: 586},
    {x: 730, y: 593},
    {x: 732, y: 636},
    {x: 768, y: 639},
    {x: 661, y: 689},
    {x: 693, y: 691},
    {x: 874, y: 686},
    {x: 663, y: 743},
    {x: 909, y: 738},
    {x: 1288, y: 744},
    {x: 870, y: 786},
    {x: 628, y: 844},
    {x: 656, y: 838},
    {x: 446, y: 888},
    {x: 907, y: 887},
    {x: 662, y: 939},
];

class TownPage1 extends React.Component {

    constructor(props) {
        super(props);

        //update global state and assets data
        globalState = this.props.location.state;
        data = globalState.assets[1];
        preloadData = globalState.assets[2];

        //refs
        this.fullscreenBg = null;


        //add base track
        {globalState.baseAudioRef
            && (
              globalState.baseAudioRef.updateTrack(1, globalState.cdnUrl + data.soundfile1) //town page
            )
        }

        
        aniTitleProps = {
            count: 0,
            numTreesRemoved: 0,
            trees: [],
            treeArea: "title"
        }
        aniTextProps = {
            count: 0,
            numTreesRemoved: 0,
            trees: [],
            treeArea: "text"
        }
        aniMapProps = {
            count: 0,
            numTreesRemoved: 0,
            trees: [],
            treeArea: "map"
        }

        titleTrees = {
            trees: []
        }
        textTrees = {
            trees: []
        } 
        mapTrees = {
            trees: []
        } 
        staticTrees = {
            trees: []
        } 
        staticBottomTrees = {
            trees: []
        }
    }

    state = {
        textVisible: false,
    }




    componentDidMount = () => {

        setTimeout(() => {
            this.refs.assetBomberRef.play(5);
            globalState.baseAudioRef.updateVolume(2, 75); 
        }, globalState.transitionDuration);

        

        //Update dimensions
        this.updateDimensions();

        //Add resize listener
        window.addEventListener("resize", this.updateDimensions);


        //animate trees out
        let i;


        //Title Trees
        for(i=1; i<=titleTreePositions.length; i++){
            aniTitleProps.trees.push(i)
        }
        shuffle(aniTitleProps.trees);
        gsap.to(aniTitleProps, treeSpeed, {delay:0.5, count:titleTreePositions.length, onUpdate:this.refs.titleTreesRef.updateTrees, onUpdateParams:[aniTitleProps], ease:Quad.easeInOut});
        

        //Text Trees
        for(i=1; i<=textTreePositions.length; i++){
            aniTextProps.trees.push(i)
        }
        shuffle(aniTextProps.trees);
        gsap.to(aniTextProps, treeSpeed, {delay:1, count:textTreePositions.length, onUpdate:this.refs.textTreesRef.updateTrees, onUpdateParams:[aniTextProps], ease:Quad.easeInOut});

        //Map Trees
        for(i=1; i<=mapTreePositions.length; i++){
            aniMapProps.trees.push(i)
        }
        shuffle(aniMapProps.trees);
        gsap.to(aniMapProps, treeSpeed, {delay:3.5, count:mapTreePositions.length, onUpdate:this.refs.mapTreesRef.updateTrees, onUpdateParams:[aniMapProps], ease:Quad.easeInOut});



        //Preload assets from next page
        preloadImages([
            globalState.cdnUrl + preloadData.slide1, 
            globalState.cdnUrl + preloadData.slide2, 
            globalState.cdnUrl + preloadData.slide4, 
            globalState.cdnUrl + preloadData.text1
        ], 
            this.preloadCallback);

    }

    preloadCallback = () => {
        this.refs.prevNextRef.showPrevNextBtns(globalState.showPrevNextDelay);
    }



    componentWillUnmount() {
        //remove base track
        //globalState.baseAudioRef.updateTrack(1, false);

        //remove event listeners
        window.removeEventListener("resize", this.updateDimensions);

        //kill tree removal tweens
        gsap.killTweensOf(aniTitleProps);
        gsap.killTweensOf(aniMapProps);
        gsap.killTweensOf(aniTextProps);
    }



    isTransitioning = () => {

        //pause animations
    }

    updateDimensions = () => {
        //console.log('updateDimensions');
        //scaleFullscreen(this.refs.fullscreenBgRef, window.innerWidth, window.innerHeight, 1184, 666);
    }

    

    render() {

        return (

            <>

                <link rel="prefetch" href="/town/2" />

                <PrevNext globalState={globalState} ref="prevNextRef"
                    nextLocation="/town/2" prevLocation="/town"
                    isTransitioning={this.isTransitioning} />

                {data && (
                <div className="fullpage-wrapper" style={{ backgroundImage: `url(${globalState.cdnUrl + data.background})`, backgroundSize: "cover", backgroundPosition: "top left" }}>
                    <SEO title="Pine Point - Town" />

                    <img className="townText" src={globalState.cdnUrl + data.townText} alt="THIS PLACE WAS PLANNED IN SOME OTHER PLACE. Pine Point had none of the organic growth of most towns, where things morph to fit trends and tides of people. Instead it was Economics 101. Fig 1. Build town. Fig 2. Fill it with things that towns need. Fig 3. Let the people in. If you mounted a social experiment on The Creation of Hometown Memories, it would probably look something like the town of Pine Point: isolated in an unforgiving landscape, and built with a singular purpose." />
                    <img className="townMap" src={globalState.cdnUrl + data.townMap} alt="Town map" />

                    <div className="town-trees town-trees--static" >
                        <Trees ref="staticTreesRef" treeArea="static" trees={staticTrees} globalState={globalState} data={data} treePositions={staticTreePositions} />
                    </div>

                    <div className="town-trees town-trees--static-bottom" >
                        <Trees ref="staticBottomTreesRef" treeArea="static-bottom" trees={staticBottomTrees} globalState={globalState} data={data} treePositions={staticBottomTreePositions} />
                    </div> 
                    
                    <div className="town-trees town-trees--title" >
                        <Trees ref="titleTreesRef" treeArea="title" trees={titleTrees} globalState={globalState} data={data} treePositions={titleTreePositions} />
                    </div>
                    
                    <div className="town-trees town-trees--text" >
                        <Trees ref="textTreesRef" treeArea="text" trees={textTrees} globalState={globalState} data={data} treePositions={textTreePositions} />
                    </div>
                    
                    <div className="town-trees town-trees--map" >
                        <Trees ref="mapTreesRef" treeArea="map" trees={mapTrees} globalState={globalState} data={data} treePositions={mapTreePositions} />
                    </div>

                    
                    <AssetBomber ref="assetBomberRef" globalState={globalState} data={data} />

                </div>
                )}
            </>
        );
    }


};

export default TownPage1
