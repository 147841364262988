import React from "react"
import { gsap } from "gsap"
import { scaleFullscreen, getRandomArbitrary, getRandomInt } from "../../helpers"

let _assets, _assetsScale, _hasPlayed, globalState, data, t;

class AssetBomber extends React.Component {

    constructor(props) {
        super(props);

        globalState = props.globalState;
        data = props.data;

        _assets = [];

        //bombed assets
        _assets.push({ asset: globalState.cdnUrl + data.townItem1, target: [-127, 231] });
        _assets.push({ asset: globalState.cdnUrl + data.townItem2, target: [78, 237] });
        _assets.push({ asset: globalState.cdnUrl + data.townItem3, target: [-320, 480] });
        _assets.push({ asset: globalState.cdnUrl + data.townItem5, target: [-74, 111] });
        _assets.push({ asset: globalState.cdnUrl + data.townItem6, target: [-372, 396] });
        _assets.push({ asset: globalState.cdnUrl + data.townItem7, target: [-8, 135] });
        _assets.push({ asset: globalState.cdnUrl + data.townItem8, target: [-284, 462] });
        _assets.push({ asset: globalState.cdnUrl + data.townItem9, target: [-30, 167] });

        _assets.push({ asset: globalState.cdnUrl + data.building1, target: [-150, 230] });
        _assets.push({ asset: globalState.cdnUrl + data.building2, target: [0, 350] });
        _assets.push({ asset: globalState.cdnUrl + data.building3, target: [-300, 400] });
        _assets.push({ asset: globalState.cdnUrl + data.building4, target: [-75, 120] });
        _assets.push({ asset: globalState.cdnUrl + data.building5, target: [-375, 400] });
        _assets.push({ asset: globalState.cdnUrl + data.building6, target: [0, 135] });
        _assets.push({ asset: globalState.cdnUrl + data.building7, target: [-250, 460] });
        _assets.push({ asset: globalState.cdnUrl + data.building8, target: [-30, 160] });

        _assets.push({ asset: globalState.cdnUrl + data.person1, target: [-150, 230] });
        _assets.push({ asset: globalState.cdnUrl + data.person2, target: [0, 350] });
        _assets.push({ asset: globalState.cdnUrl + data.person3, target: [-300, 400] });
        _assets.push({ asset: globalState.cdnUrl + data.person4, target: [-75, 120] });
        _assets.push({ asset: globalState.cdnUrl + data.person5, target: [-375, 400] });
        _assets.push({ asset: globalState.cdnUrl + data.person6, target: [0, 135] });
        _assets.push({ asset: globalState.cdnUrl + data.person7, target: [-250, 460] });
        _assets.push({ asset: globalState.cdnUrl + data.person8, target: [-30, 160] });

        
    }

    componentDidMount = () => {

        console.log("asset bomber component did mount");

        //Update dimensions
        this.updateDimensions();
        //Add resize listener
        window.addEventListener("resize", this.updateDimensions);

    }

    componentWillUnmount() {
        //remove resize listener
        window.removeEventListener("resize", this.updateDimensions);
        this.killAnimations();
    }


    //clear all animations
    killAnimations = () => {
        t.pause();
        gsap.killTweensOf(t);
    }

    updateDimensions = () => {
        console.log('updateDimensions');
        this.refs.assetBomberWrapperRef.style.width = globalState.screensize.defaultWidth + "px";
        this.refs.assetBomberWrapperRef.style.height = globalState.screensize.defaultHeight + "px";

        scaleFullscreen(this.refs.assetBomberWrapperRef, window.innerWidth, window.innerHeight, globalState.screensize.defaultWidth, globalState.screensize.defaultHeight);

        _assetsScale = window.innerWidth / globalState.screensize.defaultWidth;

        if (globalState.screensize.defaultHeight * _assetsScale < window.innerHeight) {
            _assetsScale = window.innerHeight / globalState.screensize.defaultHeight;
        }

        console.log("_assetsScale: " + _assetsScale);

        //this.treesRef.updateDimensions(globalState.screensize.defaultWidth, globalState.screensize.defaultHeight);
    }

    play = (delay = 0) => {
        if (_hasPlayed) return;

        let time = 5; //4; //5;
        let len = _assets.length;
        let randomDelay = 0;

        
        
        for (let i = 0; i < len; i++) {
            //let o = _assets[i];
            let randomX, randomY, randomScale, randomTime;
            let p = _assets[i].target; //x, y point
            let a = this.refs["a" + (i + 1)];
            let r = Math.random() * 360 - 180;

            let tr = r + (Math.random() * 180 - 90);
            randomDelay = Math.random(); //0.8; //0.5; //0.5;
            randomScale = getRandomArbitrary(10, 15); //getRandomArbitrary(35, 40);
            randomTime = time + Math.random();
            
            
            //console.log("r:" + r + ", tr: " + tr);

            //getRandomInt(2) === 1 ? randomX = getRandomArbitrary(-1200, -700) : randomX = getRandomArbitrary(700, 1200);
            randomX = getRandomArbitrary(-1200, -700);
            getRandomInt(2) === 1 ? randomY = getRandomArbitrary(-300, -150) : randomY = getRandomArbitrary(window.innerHeight, window.innerHeight+500);
            //randomY = 150;
            //randomZ = -1300;

            //set starting properties
            //gsap.set(a, { scale: randomScale, rotation: r, x: randomX*_assetsScale, y: randomY*_assetsScale, z: -1300*_assetsScale });
            gsap.set(a, { scale: randomScale, rotation: r, x: randomX, y: randomY, z: -1300 });

            //console.log("start: scale: " + randomScale + ", rotation: " + r + ", x: " + randomX + ", y: " + randomY);
            //console.log((i / 2 + delay) + randomDelay);
            
            //animate assets
            t = gsap.to(a, randomTime, {
                //delay: (i * 0.75 + delay) + randomDelay,
                delay: (i + delay) + randomDelay, //(i / 2 + delay) + randomDelay,
                rotation: tr,
                scaleX: 0,
                scaleY: 0,
                z: 200,
                x: p[0], ///_assetsScale, //x
                y: p[1], ///_assetsScale, //y
                onStart: this.handleTweenStart,
                onStartParams: [a],
                onComplete: this.handleTweenEnd,
                onCompleteParams: [a],
                //ease: Power0.easeIn
            });

            t.play();
        }
    }
    

    handleTweenStart = (target) => {
        //target.style.visible = true;
        //target.style.visibility = "visible";
        target.classList.add("visible");
        /*setTimeout(()=>{
            if(target){
                //console.log(target);
            }
        }, 1000); //2000
        */
    }
    

    handleTweenEnd = (target) => {
        //target.parent.removeChild(target);
        //target.style.display = "none";
        if(target){
            target.style.visibility = "hidden";
        }
        //console.log(target);
    }

    render() {
        
        return (
            <div ref="assetBomberWrapperRef" className="assetBomberWrapper">
                <div className="assets">
                    <img className="bomberAsset" ref="a1" src={_assets[0].asset} alt="" style={{zIndex: 1}} />
                    <img className="bomberAsset" ref="a2" src={_assets[1].asset} alt="" style={{zIndex: 2}} />
                    <img className="bomberAsset" ref="a3" src={_assets[2].asset} alt="" style={{zIndex: 3}} />
                    <img className="bomberAsset" ref="a4" src={_assets[3].asset} alt="" style={{zIndex: 4}} />
                    <img className="bomberAsset" ref="a5" src={_assets[4].asset} alt="" style={{zIndex: 5}} />
                    <img className="bomberAsset" ref="a6" src={_assets[5].asset} alt="" style={{zIndex: 6}} />
                    <img className="bomberAsset" ref="a7" src={_assets[6].asset} alt="" style={{zIndex: 7}} />
                    <img className="bomberAsset" ref="a8" src={_assets[7].asset} alt="" style={{zIndex: 8}} />

                    <img className="bomberAsset" ref="a9" src={_assets[8].asset} alt="" style={{zIndex: 9}} />
                    <img className="bomberAsset" ref="a10" src={_assets[9].asset} alt="" style={{zIndex: 10}} />
                    <img className="bomberAsset" ref="a11" src={_assets[10].asset} alt="" style={{zIndex: 11}} />
                    <img className="bomberAsset" ref="a12" src={_assets[11].asset} alt="" style={{zIndex: 12}} />
                    <img className="bomberAsset" ref="a13" src={_assets[12].asset} alt="" style={{zIndex: 13}} />
                    <img className="bomberAsset" ref="a14" src={_assets[13].asset} alt="" style={{zIndex: 14}} />
                    <img className="bomberAsset" ref="a15" src={_assets[14].asset} alt="" style={{zIndex: 15}} />
                    <img className="bomberAsset" ref="a16" src={_assets[15].asset} alt="" style={{zIndex: 16}} />

                    <img className="bomberAsset" ref="a17" src={_assets[16].asset} alt="" style={{zIndex: 17}} />
                    <img className="bomberAsset" ref="a18" src={_assets[17].asset} alt="" style={{zIndex: 18}} />
                    <img className="bomberAsset" ref="a19" src={_assets[18].asset} alt="" style={{zIndex: 19}} />
                    <img className="bomberAsset" ref="a20" src={_assets[19].asset} alt="" style={{zIndex: 20}} />
                    <img className="bomberAsset" ref="a21" src={_assets[20].asset} alt="" style={{zIndex: 21}} />
                    <img className="bomberAsset" ref="a22" src={_assets[21].asset} alt="" style={{zIndex: 22}} />
                    <img className="bomberAsset" ref="a23" src={_assets[22].asset} alt="" style={{zIndex: 23}} />
                    <img className="bomberAsset" ref="a24" src={_assets[23].asset} alt="" style={{zIndex: 24}} />
                </div>
            </div>
        )
    }
}
export default AssetBomber